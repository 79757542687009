import React, {useEffect, useState} from 'react'
import './styles/main.css'
import {BrowserRouter, Link} from "react-router-dom"
import {AuthContext} from './context/Context'
import AppRouter from "./pages/AppRouter"
import Loadit from "./funcs/Load";

interface Company {
    id: string
    nm: string
    osn: Array<boolean | null>
    fiz?: Array<boolean | null>
    ur?: Array<boolean | null>
    gos?: Array<boolean | null>
    [key: string]: any
}
interface City {
    [key: number]: string
}
interface Nomenclature {
    id: number
    ca: number | null
    inside: any
    name: string | null
    nopapa: boolean | null
    params: any
    paramslog?: any
    pogonazh: string
    price: Array<string | null>
    round: boolean
    unit: string
    [key: string]: any
}
interface SysDat {
    cats: {[key: number]: string;}
    cities: City
    companies: {[key: number]: Company;}
    nomenclature: {[key: number]: Nomenclature;}
    payment_type: Array<any>
    st: {[key: number]: string;}
    units: Array<any>
    users: Array<any>
    variables: {[key: number]: string;}
}

const App: React.FC = () => {

    // queries
    const Q_ = function (str: string): HTMLElement {
        const element = document.querySelector(str) as HTMLElement | null;
        if (!element) {
            let div = document.createElement('div')
            return div
        }else{
            return element
        }
    }
    const Qs_ = function (str: string): NodeListOf<HTMLElement> {
        const elements = document.querySelectorAll(str) as NodeListOf<HTMLElement>
        if (elements && elements.length === 0) {
            // Создаем виртуальный DIV
            const div = document.createElement('div');

            // Преобразуем массив с элементом в NodeList
            const virtualNodeList = document.createDocumentFragment();
            virtualNodeList.appendChild(div);

            return virtualNodeList.childNodes as NodeListOf<HTMLElement>;
        }else{
            return elements
        }
    }
    const Lg_ = function (str: string): string | null{
        return localStorage.getItem(str)
    }
    const Ls_ = function (str: string, data: string): void{
        return localStorage.setItem(str, data)
    }
    // user
    let storageAuth:boolean = false
    if(Lg_('auth')){storageAuth = true}
    const [isAuth, setIsAuth] = useState<boolean>(storageAuth)
    const [uploadUserData] = useState(async () => {
        if(Lg_('auth-login') && Lg_('auth-pswd')){
            const response = await Loadit.autoLoginCheck(Lg_('auth-login'), Lg_('auth-pswd'))
            if(response.ID){
                Ls_('auth-id', response.ID)
                Ls_('auth-pswd', response.token)
                Ls_('auth-login', response.nickname[0])
                Ls_('auth-name', response.first_name[0])
                Ls_('auth-surname', response.last_name[0])
                Ls_('auth-role-id', response.is_role[0])
                Ls_('auth-role', response.is_role[1])
                Ls_('auth-role-prices', response.is_role[2])
                Ls_('auth-role-cities', response.is_role[3])
                Ls_('auth-role-prices-def', response.is_role[5])
                Ls_('auth-company', response.company[1])
                Ls_('auth-company-id', response.company[0])
                Ls_('auth-city', response.city[0])
                Ls_('auth-city-id', response.city[1])
                Ls_('auth-signature', response.is_sign)
                Ls_('auth-phone', response.contacts[0])
                Ls_('auth-whatsapp', response.contacts[1])
                Ls_('auth-telegram', response.contacts[2])

                // send data to console for workers
                if(isAuth && Lg_('auth-id') === '1'){
                    console.group('----------------------USER STORAGE:')
                    console.log('ID: ' + Lg_('auth-id'))
                    console.log('TOKEN: ' + Lg_('auth-pswd'))
                    console.log('NICK: ' + Lg_('auth-login'))
                    console.log('NAME: ' + Lg_('auth-name'))
                    console.log('SURNAME: ' + Lg_('auth-surname'))
                    console.log('ROLE: ' + Lg_('auth-role'))
                    console.log('ROLE-ID: ' + Lg_('auth-role-id'))
                    console.log('ROLE-PRICES: ' + Lg_('auth-role-prices'))
                    console.log('ROLE-PRICES-DEFAULT: ' + Lg_('auth-role-prices-def'))
                    console.log('ROLE-CITIES: ' + Lg_('auth-role-cities'))
                    console.log('COMPANY: ' + Lg_('auth-company'))
                    console.log('COMPANY-ID: ' + Lg_('auth-company-id'))
                    console.log('CITY: ' + Lg_('auth-city'))
                    console.log('CITY-ID: ' + Lg_('auth-city-id'))
                    console.log('SIGNATURE: ' + Lg_('auth-signature'))
                    console.log('PHONE: ' + Lg_('auth-phone'))
                    console.log('WHATSAPP: ' + Lg_('auth-whatsapp'))
                    console.log('TELEGRAM: ' + Lg_('auth-telegram'))
                    console.groupEnd()
                    console.log(response)
                }
            }else{
                localStorage.removeItem('auth')
                setIsAuth(false)
                return false
            }
            return true
        }else{
            localStorage.removeItem('auth')
            setIsAuth(false)
            return false
        }
    })
    // steps and indicators
    const [ordersReload, setOrdersReload] = useState(false)
    const [sumReady, setSumReady] = useState(false)
    // filters
    const [isHideOldChecked, setIsHideOldChecked] = useState('')  // name is wrong - it used for filter orders by step
    const [filterCity, setFilterCity] = useState('')
    const [filterClient, setFilterClient] = useState('')
    const [filterPartner, setFilterPartner] = useState('')
    const [filterWorker, setFilterWorker] = useState('')
    const [filterPaType, setFilterPaType] = useState(1)
    const [filterPaCity, setFilterPaCity] = useState('')
    const [filterPaStep, setFilterPaStep] = useState('')
    const [filterPaClient, setFilterPaClient] = useState('')
    const [filterPaPartner, setFilterPaPartner] = useState('')
    const [filterPaWorker, setFilterPaWorker] = useState('')
    // listeners
    const [popWasOpen, setPopWasOpen] = useState(0)
    // popups
    const [popClientBlank] = useState<Company>({
        id: 'new',
        nm: 'Новый клиент',
        osn: [false, false, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,],
        fiz: [false, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,],
        ur: [false, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,],
        gos: [false, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,],
    })
    const [popClientCur, setPopClientCur] = useState('client')
    const [popClient, setPopClient] = useState<any>(popClientBlank)
    const [popStock, setPopStock] = useState<any>('')
    const [popKP_picDel, setPopKP_picDel] = useState('')
    const [popKP_txtDel, setPopKP_txtDel] = useState('')
    const [popKP_setDel, setPopKP_setDel] = useState('')
    // get data
    const [isSysDat, setIsSysDat] = useState<any>(async () => {
        const response = await Loadit.getSystem() as SysDat

        // fill the missing keys in clients and partners
        let comps1 = JSON.parse(JSON.stringify(response.companies))
        Object.entries(response.companies).forEach(([key, co]) => {
            if (!co.fiz) {
                comps1[key] = comps1[key] || {}
                comps1[key].fiz = popClientBlank.fiz
            }
            if (!co.ur) {
                comps1[key] = comps1[key] || {}
                comps1[key].ur = popClientBlank.ur
            }
            if (!co.gos) {
                comps1[key] = comps1[key] || {}
                comps1[key].gos = popClientBlank.gos
            }
        });
        response.companies = comps1;

        // send data to console for workers
        if (isAuth && Lg_('auth-id') === '1') {
            console.log('----------------------SYSTEM DATA:')
            console.log(response)
        }

        setIsSysDat(response)
    })
    const [orders, setOrders] = useState(async () => {
        let response = await Loadit.getOrders('two', 100, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        setOrders(response)
        setOrdersReload(!ordersReload)
    })
    const [paOrders, setPaOrders] = useState(async () => {
        let response = await Loadit.getPaOrders(filterPaType, 999, '', filterPaCity, filterPaStep, filterPaClient, filterPaPartner, filterPaWorker)
        setPaOrders(response)
    })
    const [isPrices, setIsPrices] = useState<number>(() => {
        let tmpPr = '1'  as any
        let tmpP_ = Lg_('auth-role-prices') as string | null
        let tmpD_ = Lg_('auth-role-prices-def') as string | null

        if(tmpP_ && tmpD_ && tmpP_ !== 'null' && tmpD_ !== 'null'){
            if(tmpP_ === '0'){
                tmpPr = tmpD_
            }else if(tmpP_ === '1'){
                tmpPr = '1'
            }else if(tmpP_ === '2'){
                tmpPr = '2'
            }else if(tmpP_ === '3'){
                tmpPr = '3'
            }else if(tmpP_ === '4'){
                tmpPr = '1'
                if(tmpD_ === '2'){
                    tmpPr = '2'
                }
            }else if(tmpP_ === '5'){
                tmpPr = '1'
                if(tmpD_ === '3'){
                    tmpPr = '3'
                }
            }else if(tmpP_ === '6'){
                tmpPr = '2'
                if(tmpD_ === '3'){
                    tmpPr = '3'
                }
            }
        }

        return tmpPr*1 as number
    })
    const [isPage, setIsPage] = useState('orders')
    // devices
    const [isLandscape, setIsLandscape] = useState(window.matchMedia('(min-width: 25cm)').matches)
    const [isLaptop, setIsLaptop] = useState(window.matchMedia('(min-width: 25cm) and (max-width: 40cm)').matches)
    const [isPad, setIsPad] = useState(window.matchMedia('(max-width: 25cm) and (min-width: 15cm)').matches)

    // logout
    function logoutUser() {
        localStorage.removeItem('auth')
        setIsAuth(false)
    }

    // delete order
    function deleteOrder(str: any){
        Q_('.popup-sure .popup-sure-btns-delete').dataset.type = 'order'
        Q_('.popup-sure .popup-sure-btns-delete').dataset.num = str
        Q_('.popup-sure-alert').style.display = 'block'
        Q_('.popup-sure').style.display = 'grid'
    }
    async function deleteOrderSure(){
        Q_('.popup-sure').style.display = 'none'
        let respoX = await Loadit.deleteOrder(Q_('.popup-sure-btns-delete').dataset.num)

        // reload orders
        let countOfHaving = Qs_('.page-orders-wrp .one-order').length - 1
        if(countOfHaving < 1){countOfHaving = 100}
        let response = await Loadit.getOrders('two', countOfHaving, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        setOrders(response)
        setOrdersReload(!ordersReload)

        // reload pa-orders
        let resp3 = await Loadit.getPaOrders(filterPaType, 999, '', filterPaCity, filterPaStep, filterPaClient, filterPaPartner, filterPaWorker)
        setPaOrders(resp3)

        // check: deleted or not this order
        let orderDeleted = false
        if(isPage === 'order' || isPage === 'pa_order'){
            let respoZ = await Loadit.checkOrder(Q_('.page-order-wrp').getAttribute('data-id'))
            if(!respoZ || respoZ == 0){
                orderDeleted = true
            }
        }

        // back to orders or root order if we in order / suborder
        let returnAfter = 50
        if(isPage === 'order' || isPage === 'pa_order'){
            if(Q_('.page-order-wrp').getAttribute('data-id') == respoX || orderDeleted){
                returnAfter = 1000
                setTimeout(function () {
                    if(isPage === 'pa_order'){
                        Q_('.page-order-backtoroot').click()
                        openMessage("Готово!", "Подзаказ поставщику удалён, и вы были перенаправлены в корневой заказ от клиента")
                    }else{
                        Q_('.content-head-menu-orders').click()
                    }
                },1000)
            }else{
                let tEl = Q_('.page-order-suborders .one-suborder-wrp[data-id="'+respoX+'"]')
                if(tEl){
                    let tEl2 = tEl.closest('.one-suborder-in') as HTMLElement | null
                    tEl.remove()
                    if(tEl2){
                        if(!tEl2.querySelector('.one-suborder-wrp')){
                            let tEl3 = tEl2.closest('.one-suborder-wrp') as HTMLElement | null
                            if(tEl3){
                                let tEl4 = tEl3.querySelector('.one-suborder .one-suborder-arr') as HTMLElement | null
                                if(tEl4){
                                    tEl4.classList.remove('one-suborder-arr')
                                    tEl4.classList.add('one-suborder-arr2')
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimeout(function () {
            Q_('.popup-wait').style.display = 'none'
        },returnAfter)
    }

    // delete kp txt & pic & set
    async function deleteKPpicSure(){
        Q_('.popup-sure').style.display = 'none'

        let respoX = await Loadit.deleteKPpic(Q_('.popup-sure-btns-delete').dataset.num)
        setPopKP_picDel(respoX)

        Q_('.popup-wait').style.display = 'none'
    }
    async function deleteKPtxtSure(){
        Q_('.popup-sure').style.display = 'none'

        let respoX = await Loadit.deleteKPtxt(Q_('.popup-sure-btns-delete').dataset.num)
        setPopKP_txtDel(respoX)

        Q_('.popup-wait').style.display = 'none'
    }
    async function deleteKPsetSure(){
        Q_('.popup-sure').style.display = 'none'

        let respoX = await Loadit.deleteKPset(Q_('.popup-sure-btns-delete').dataset.num)
        setPopKP_setDel(respoX)

        Q_('.popup-wait').style.display = 'none'
    }

    // delete client
    function deleteClient(str: any) {
        Q_('.popup-sure').style.display = 'grid'
        Q_('.popup-sure .popup-sure-btns-delete').dataset.type = 'client';
        Q_('.popup-sure .popup-sure-btns-delete').dataset.num = str;
    }
    async function deleteClientSure(){
        Q_('.popup-sure').style.display = 'none'

        let respoX = await Loadit.deleteClient(Q_('.popup-sure-btns-delete').dataset.num)

        let tmpSysDat = JSON.parse(JSON.stringify(isSysDat))
        delete tmpSysDat.companies[respoX]
        setIsSysDat(tmpSysDat)

        Q_('.popup-wait').style.display = 'none'
    }
    // client popup tabs switcher
    function switchClientTabs(str: any){
        Q_('.popup-client .popup-menu-one.active').classList.remove('active')
        Q_('.popup-client .popup-body-box.active').classList.remove('active')
        Q_('.popup-client .popup-menu-one.popup-client-'+str).classList.add('active')
        Q_('.popup-client .popup-body-box.popup-client-'+str).classList.add('active')
    }
    // save client
    async function saveClient(){
        // collect params for saving
        const tmpArrForSend = new FormData()
        Qs_('.popup-client-body .param-field').forEach((el: HTMLElement) => {
            let tmpVal1:string = '0'
            let tmpVal2:any
            let tmpVal3:string = ''

            if(el.getAttribute('type') === 'checkbox' && el instanceof HTMLInputElement){
                tmpVal1 = el.checked ? '1' : '0'
                const name = el.getAttribute('name')
                if (name !== null) {
                    tmpArrForSend.append(name, tmpVal1)
                }


            }else if(el.getAttribute('type') === 'file' && el instanceof HTMLInputElement){
                if(el.getAttribute('data-del') === '1'){
                    tmpVal2 = 'delete'
                }else if(el.files){
                    tmpVal2 = el.files[0]
                }
                if(tmpVal2){
                    const name = el.getAttribute('name')
                    if (name !== null) {
                        tmpArrForSend.append(name, tmpVal2)
                    }
                }


            }else if(el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement || el instanceof HTMLSelectElement){
                tmpVal3 = el.value
                const name = el.getAttribute('name')
                if (name !== null) {
                    tmpArrForSend.append(name, tmpVal3)
                }
            }
        })

        // send data to save
        let response1 = await Loadit.saveClient(popClient.id, tmpArrForSend, Lg_('auth-id'))

        // upload companies in sysDat
        let tmpSys = JSON.parse(JSON.stringify(isSysDat))
        tmpSys.companies[response1.id] = response1
        setIsSysDat(tmpSys)

        // select just new created client / partner in list (if it order page)
        if(Q_('.page.page-order')){
            setTimeout(function () {
                let curEl = Q_('.page-order-params select.param-field[name="client"]') as HTMLInputElement | null
                if(curEl){
                    curEl.value = response1.id
                }
            },500)
        }

        // reset popup data
        setPopClient(popClientBlank)

        Q_('.popup-wait').style.display = 'none';

        // action for client page
        if(isPage === 'client'){
            openMessage('Сохранено!', 'Информация о клиенте успешно сохранена. Если вы позже захотите внести правки, то снова перейдите по этой ссылке.')
        }
    }
    function deleteImageFromClient(inName:any, inArr:any, inNum:any){
        let tmpClient = JSON.parse(JSON.stringify(popClient))
        tmpClient[inArr][inNum] = ''
        setPopClient(tmpClient)
        let tmpInpEl = Q_('.popup-client input[name="'+inName+'"]') as HTMLInputElement | null
        if(tmpInpEl){
            tmpInpEl.value = ''
            tmpInpEl.setAttribute('data-del', '1')
        }
    }
    function changeImageInClient(inElm:any, inArr:any, inNum:any){
        let f = inElm.files[0]
        let fr = new FileReader()
        fr.onload = function(ev2) {
            let tmpClient = JSON.parse(JSON.stringify(popClient))
            if(ev2.target){
                tmpClient[inArr][inNum] = ev2.target.result
                setPopClient(tmpClient)
                inElm.setAttribute('data-del', '')
            }
        };
        fr.readAsDataURL(f)
    }

    // popup message
    function openMessage(inTit:string, inBody:string){
        let tmTit = 'У вас нет доступа :('
        if(inTit){tmTit = inTit}

        let tmBody = 'Для этого действия необходимы права, которых нет у вашего аккаунта.'
        if(inBody){tmBody = inBody}

        Q_('.popup-message .popup-tit').innerText = tmTit
        Q_('.popup-message #mess-txt').innerText = tmBody
        Q_('.popup-message').style.display = 'grid'
    }

    // random url string
    function genRndString(inLength: number) {
        let result = '';
        let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < inLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = 's'+result
        return result;
    }

    // close mob menu
    function closeMobMenu() {
        Q_('.content-head-menu').classList.remove('active')
        Q_('.content-head-who').classList.remove('active')
        Q_('.content-head').classList.remove('active')
    }

    // create rout for noms
    function pushRout(target: HTMLElement, readyArr: Array<any>){
        let tmArr = []
        if(readyArr){
            tmArr = readyArr
        }

        let tmPaNom = target.closest('.nom') as HTMLElement
        if(tmPaNom.getAttribute('data-var')){
            tmArr.push(tmPaNom.getAttribute('data-var'))
        }else{
            tmArr.push('__'+tmPaNom.getAttribute('data-pos'))
        }

        // continue?
        tmPaNom = tmPaNom.closest('.entrails') as HTMLElement
        if(tmPaNom){
            if(!tmPaNom.classList.contains('element-inside-wrp')){
                return pushRout(tmPaNom, tmArr)
            }else{
                return tmArr
            }
        }else{
            return tmArr
        }
    }

    // reset popWasOpen if page changed
    useEffect(() => {
        setPopWasOpen(0)
    }, [window.location.href])

    // ones for first load and for closing
    function closeAllPops(){
        let openedPops = Qs_('.popup[style="display: grid;"]')
        if(openedPops.length === 1){
            openedPops.forEach((po)=>{
                let poClose: HTMLElement | null = po.querySelector('.popup-close')
                if(poClose){
                    poClose.click()
                }
            })
            return true
        }

        if(openedPops.length > 1){
            // if opened more when 1 popup - return TRUE
            return true
        }else{
            return false
        }
    }
    function pushStepToStory(str:string){
        // if function called from interval
        if(str === 'interval'){
            let mLogo = Q_('.content-head-logo') as HTMLElement
            if(mLogo && mLogo.innerHTML){
                if(!(mLogo.getAttribute('data-pop') as any * 1)){
                    if(Q_('.popup[style="display: grid;"]')){
                        setPopWasOpen(() => {
                            return 1
                        })
                    }else{
                        return
                    }
                }else{
                    return
                }
            }else{
                return
            }
        }

        let urlNow = new URL(window.location.href)
        window.history.pushState({}, '', urlNow.pathname + urlNow.search)
    }
    useEffect(() => {
        // listener of resize
        window.addEventListener("resize", () => {

            // Mob device ?
            if(window.matchMedia('(min-width: 25cm)').matches){
                setIsLandscape(true)
            }else{
                setIsLandscape(false)
            }

            // Pad ?
            if(window.matchMedia('(max-width: 25cm)').matches && window.matchMedia('(min-width: 15cm)').matches){
                setIsPad(true)
            }else{
                setIsPad(false)
            }

            // Laptop ?
            if(window.matchMedia('(min-width: 25cm)').matches && window.matchMedia('(max-width: 40cm)').matches){
                setIsLaptop(true)
            }else{
                setIsLaptop(false)
            }

        })

        // listener of clicks
        document.addEventListener('click', function(e){
            // for close popups
            let tmpTarget = e.target as HTMLElement | null
            if(tmpTarget && tmpTarget.classList.contains('popup-close')){
                setTimeout(function (){
                    let openedPops = Qs_('.popup[style="display: grid;"]')
                    if(openedPops.length === 0){
                        window.history.back()
                        setPopWasOpen(0)
                    }
                },200)
            }
        });

        // listener of keydown
        document.addEventListener("keydown", function(e) {
            // for ENTER
            if (e.keyCode === 13){
                // login form
                if(Q_('#get-login')){
                    Q_('#get-login').click()
                }else

                    // newPrice popup
                if(Q_('.popup-newprice')){
                    if(Q_('.popup-newprice').style.display === 'grid'){
                        Q_('.popup-newprice-close').click()
                    }
                }
            }

            // for ESC
            if (e.keyCode === 27){
                closeAllPops()
            }
        })

        // listener of changes in browser story
        window.addEventListener('popstate', function() {
            if(closeAllPops()){
                // if opened more when 1 popup - you cant click on BACK button in browser
                window.history.go(1)
            }
        })

        // push new step to browser story at first app loading
        pushStepToStory('')
        // interval for set new step in browser story if pop was open on page
        const intervalPop = setInterval(() => {
            pushStepToStory('interval')
        }, 1000)

        // intervals of orders reloading
        const intervalOrders = setInterval(async function () {
            // collect filters
            let tmpPage = 'orders'
            let tmpStep = ''
            let tmpCity = ''
            let tmpClient = ''
            let tmpPartner = ''
            let tmpWorker = ''
            let tmpEl = Q_('.content-head-logo') as HTMLElement | null
            if(tmpEl){
                tmpPage = tmpEl.getAttribute('data-page') as string
                tmpStep = tmpEl.getAttribute('data-step') as string
                tmpCity = tmpEl.getAttribute('data-city') as string
                tmpClient = tmpEl.getAttribute('data-client') as string
                tmpPartner = tmpEl.getAttribute('data-partner') as string
                tmpWorker = tmpEl.getAttribute('data-worker') as string
            }

            // calc count for loading
            let countOfHaving = 100
            if(tmpPage === 'orders'){
                let listTmp = Qs_('.page-orders-wrp .one-order')
                if(listTmp){
                    countOfHaving = Qs_('.page-orders-wrp .one-order').length
                    if(!countOfHaving){countOfHaving = 100}
                }
            }

            // get and set
            let response = await Loadit.getOrders('two', countOfHaving, '', tmpCity, tmpStep, tmpClient, tmpPartner, tmpWorker)
            setOrders(response)

            setOrdersReload(!ordersReload)
        }, 60000)
        const intervalPaOrders = setInterval(async function () {
            // collect filters
            let tmpType = ''
            let tmpStep = ''
            let tmpCity = ''
            let tmpClient = ''
            let tmpPartner = ''
            let tmpWorker = ''
            let tmpEl = Q_('.content-head-logo')
            if(tmpEl){
                tmpType = tmpEl.getAttribute('data-pa-type') as string
                tmpStep = tmpEl.getAttribute('data-pa-step') as string
                tmpCity = tmpEl.getAttribute('data-pa-city') as string
                tmpClient = tmpEl.getAttribute('data-pa-client') as string
                tmpPartner = tmpEl.getAttribute('data-pa-partner') as string
                tmpWorker = tmpEl.getAttribute('data-pa-worker') as string
            }

            // get and set
            let response = await Loadit.getPaOrders(tmpType, 999, '', tmpCity, tmpStep, tmpClient, tmpPartner, tmpWorker)
            setPaOrders(response)
        }, 63000)

        // finish of component work
        return () => {
            clearInterval(intervalPop)
            clearInterval(intervalOrders)
            clearInterval(intervalPaOrders)
        }
    }, [])

    return(
        <AuthContext.Provider value={{
            isLandscape,
            isLaptop,
            isPad,

            isAuth, setIsAuth,
            isSysDat,
            orders, setOrders,
            paOrders, setPaOrders,
            isPrices, setIsPrices,
            setIsPage,

            sumReady, setSumReady,
            ordersReload, setOrdersReload,

            isHideOldChecked, setIsHideOldChecked,
            filterCity, setFilterCity,
            filterClient, setFilterClient,
            filterPartner, setFilterPartner,
            filterWorker, setFilterWorker,

            filterPaType, setFilterPaType,
            filterPaStep, setFilterPaStep,
            filterPaCity, setFilterPaCity,
            filterPaClient, setFilterPaClient,
            filterPaPartner, setFilterPaPartner,
            filterPaWorker, setFilterPaWorker,

            popClientBlank, setPopClient, setPopClientCur,
            setPopStock,
            popKP_picDel, setPopKP_picDel,
            popKP_txtDel, setPopKP_txtDel,
            popKP_setDel, setPopKP_setDel,

            Q_, Qs_,
            Lg_, Ls_,
            deleteOrder,
            deleteClient,
            openMessage,
            pushRout,
        }}>
            <BrowserRouter basename="/">
                {isSysDat.st
                    ?   <div className="popups">
                            <div className={`popup popup-client ${isPage === 'client' ? 'popup-client-fullscreen' : ''}`}>
                                {popClient.osn[0] || popClient.osn[1]
                                    ?   <div className="popup-wrp-dad">
                                            <div className="popup-wrp popup-client-wrp">
                                                {!isLandscape
                                                    ?   <p className="popup-tit">Редактор {popClientCur === 'client' ? 'клиента' : 'поставщика'}:</p>
                                                    :   ''
                                                }
                                                <div className="popup-menu">
                                                    <div className="popup-menu-one popup-client-osn active"
                                                         onClick={()=>switchClientTabs('osn')}>Основное</div>
                                                    {popClient.fiz[0]
                                                        ?   <div className="popup-menu-one popup-client-fiz"
                                                                 onClick={()=>switchClientTabs('fiz')}>Физ.лицо</div>
                                                        :   ''
                                                    }
                                                    {popClient.ur[0]
                                                        ?   <div className="popup-menu-one popup-client-ur"
                                                                 onClick={()=>switchClientTabs('ur')}>Юр.лицо</div>
                                                        :   ''
                                                    }
                                                    {popClient.gos[0]
                                                        ?   <div className="popup-menu-one popup-client-gos"
                                                                 onClick={()=>switchClientTabs('gos')}>Гос.учереждение</div>
                                                        :   ''
                                                    }
                                                </div>
                                                <div className="popup-body popup-client-body">
                                                    <div className="popup-body-box popup-client-osn active">
                                                        <div className="param param-line3">
                                                            <p>Название {popClientCur === 'client' ? 'клиента' : 'поставщика'} (в свободной форме)</p>
                                                            <input className="param-field" name="title" type="text" autoComplete="off"
                                                                   placeholder="Новый клиент"
                                                                   defaultValue={popClient.nm}
                                                            />
                                                        </div>          {/* Client */}
                                                        <div className="param">
                                                            <p>Город</p>
                                                            <select className="param-field" name="company_city" autoComplete="off"
                                                                    defaultValue={popClient.osn[2]
                                                                        ? popClient.osn[2]
                                                                        : Lg_('auth-city-id')
                                                                    }
                                                            >
                                                                {Object.entries(isSysDat.cities as Record<string, string>).map((el) => (
                                                                    <option value={el[0]} key={el[0]}>{el[1]}</option>
                                                                ))}
                                                            </select>
                                                        </div>                          {/* City */}
                                                        <div className="param">
                                                            <p>Телефон</p>
                                                            <input className="param-field" name="company_phone" type="text" autoComplete="off"
                                                                   placeholder="+7 (000) 000 0000"
                                                                   defaultValue={Lg_('auth-id') === '5' ? 'скрыто для вас' : popClient.osn[3]}
                                                            />
                                                        </div>                          {/* Phone */}
                                                        <div className="param">
                                                            <p>Email</p>
                                                            <input className="param-field" name="company_email" type="text" autoComplete="off"
                                                                   placeholder="name@mail.ru"
                                                                   defaultValue={Lg_('auth-id') === '5' ? 'скрыто для вас' : popClient.osn[4]}
                                                            />
                                                        </div>                          {/* Email */}
                                                        { popClientCur === 'client'
                                                            ?   <div className="param param-line3">
                                                                    <p>{ isPage !== 'client'
                                                                        ?   'Адрес проведения работ заполненный клиентом при передаче ему ссылки (скопируйте его в адрес заказа)'
                                                                        :   'Адрес проведения работ'
                                                                    }</p>
                                                                    <input className="param-field" name="client_addr" type="text" autoComplete="off"
                                                                           placeholder="г.Новосибирск, ул.Маркса, д.6"
                                                                           defaultValue={Lg_('auth-id') === '5' ? 'скрыто для вас' : popClient.osn[6]}
                                                                    />
                                                                </div>
                                                            :   ''
                                                        }                                                               {/* Work address */}
                                                        <div className="param param-line3">
                                                            <input className="param-field" name="is_fiz" type="checkbox" autoComplete="off" id="is_fiz"
                                                                   defaultChecked={popClient.fiz[0]}
                                                                   onChange={(e)=>{
                                                                       let tmpClient = JSON.parse(JSON.stringify(popClient))
                                                                       if(e.target.checked){
                                                                           tmpClient.fiz[0] = true
                                                                       }else{
                                                                           tmpClient.fiz[0] = false
                                                                       }
                                                                       setPopClient(tmpClient)
                                                                   }}
                                                            />
                                                            <label htmlFor="is_fiz">Есть данные Физ.лица</label>
                                                        </div>          {/* Физ лицо? */}
                                                        <div className="param param-line3">
                                                            <input className="param-field" name="company_is_com" type="checkbox" autoComplete="off"
                                                                   id="company_is_com"
                                                                   defaultChecked={popClient.ur[0]}
                                                                   onChange={(e)=>{
                                                                       let tmpClient = JSON.parse(JSON.stringify(popClient))
                                                                       if(e.target.checked){
                                                                           tmpClient.ur[0] = true
                                                                       }else{
                                                                           tmpClient.ur[0] = false
                                                                       }
                                                                       setPopClient(tmpClient)
                                                                   }}
                                                            />
                                                            <label htmlFor="company_is_com">Есть данные Юр.лица</label>
                                                        </div>          {/* Юр лицо? */}
                                                        <div className={`param param-line3 ${isPage === 'client'
                                                            ?   'param-hidden'
                                                            :   ''
                                                        }`}
                                                        >
                                                            <input className="param-field" name="company_is_gov" type="checkbox" autoComplete="off"
                                                                   id="company_is_gov"
                                                                   defaultChecked={popClient.gos[0]}
                                                                   onChange={(e)=>{
                                                                       let tmpClient = JSON.parse(JSON.stringify(popClient))
                                                                       if(e.target.checked){
                                                                           tmpClient.gos[0] = true
                                                                       }else{
                                                                           tmpClient.gos[0] = false
                                                                       }
                                                                       setPopClient(tmpClient)
                                                                   }}
                                                            />
                                                            <label htmlFor="company_is_gov">Есть данные Гос.учереждения</label>
                                                        </div>                                                          {/* Гос лицо? */}
                                                        <div className={`param param-line3 ${popClientCur === 'client'
                                                            ?   'param-hidden'
                                                            :   ''
                                                        }`}
                                                        >
                                                            <input className="param-field" name="is_stock" type="checkbox" autoComplete="off"
                                                                   id="is_stock" defaultChecked={popClient.osn[7]}
                                                            />
                                                            <label htmlFor="is_stock">Является складом</label>
                                                        </div>                                                          {/* is Stock? */}
                                                        <div className={`param param-line3 ${popClientCur === 'client'
                                                            ?   'param-hidden'
                                                            :   ''
                                                        }`}
                                                        >
                                                            <input className="param-field" name="company_is_client" type="checkbox" autoComplete="off"
                                                                   id="company_is_client" defaultChecked={popClient.osn[0]}
                                                            />
                                                            <label htmlFor="company_is_client">Является и клиентом тоже (отобразится в списке клиентов)</label>
                                                        </div>                                                          {/* is Client? */}
                                                        <div className={`param param-line3 ${
                                                            popClientCur === 'partner' || isPage === 'client'
                                                                ? 'param-hidden'
                                                                : ''
                                                        }`}
                                                        >
                                                            <input className="param-field" name="company_is_partner" type="checkbox" autoComplete="off"
                                                                   id="company_is_partner" defaultChecked={popClient.osn[1]}
                                                            />
                                                            <label htmlFor="company_is_partner">Является и поставщиком тоже (отобразится в списке поставщиков)</label>
                                                        </div>                                                          {/* is Partner? */}
                                                        <div className="param param-hidden">
                                                            <p>URL ключ</p>
                                                            <input className="param-field" name="client_url" type="text" autoComplete="off"
                                                                   defaultValue={popClient.osn[5] ? popClient.osn[5] : genRndString(10)}
                                                            />
                                                        </div>       {/* URL key */}
                                                    </div>
                                                    <div className="popup-body-box popup-client-fiz">
                                                        {Lg_('auth-id') === '5'
                                                            ? <div className="param param-line3"><p style={{color: 'red'}}>Важные данные скрыто для вас</p></div>
                                                            : ''
                                                        }
                                                        <div className="param">
                                                            <p>Фамилия Имя Отчество</p>
                                                            <input className="param-field" name="fiz_fio" type="text" autoComplete="off"
                                                                   placeholder="Иванов Иван Иванович"
                                                                   defaultValue={popClient.fiz[1]}
                                                            />
                                                        </div>                          {/* ФИО */}
                                                        <div className="param">
                                                            <p>Фамилия И.О. (расшифровка подписи)</p>
                                                            <input className="param-field" name="fiz_fio2" type="text" autoComplete="off"
                                                                   placeholder="Иванов И.И."
                                                                   defaultValue={popClient.fiz[9]}
                                                            />
                                                        </div>                          {/* Расшифровка подписи */}
                                                        <div className="param">
                                                            <p>Паспорт - Дата рождения</p>
                                                            <input className="param-field" name="fiz_dr" type="date" autoComplete="off"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.fiz[7]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* ДР */}
                                                        <div className="param param-line3">
                                                            <p>Паспорт - Кем выдан</p>
                                                            <input className="param-field" name="fiz_kem" type="text" autoComplete="off"
                                                                   placeholder="Отделением УФМС России по Новосибирской области"
                                                                   defaultValue={Lg_('auth-id') === '5' ? 'скрыто для вас' : popClient.fiz[4]}
                                                            />
                                                        </div>          {/* Кем выдан */}
                                                        <div className="param">
                                                            <p>Паспорт - Серия</p>
                                                            <input className="param-field" name="fiz_pass" type="number" autoComplete="off"
                                                                   placeholder="0000"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.fiz[2]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* Серия */}
                                                        <div className="param">
                                                            <p>Паспорт - Номер</p>
                                                            <input className="param-field" name="fiz_pasn" type="number" autoComplete="off"
                                                                   placeholder="000000"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.fiz[3]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* Номер */}
                                                        <div className="param">
                                                            <p>Паспорт - Код подразделения</p>
                                                            <input className="param-field" name="fiz_kod" type="text" autoComplete="off"
                                                                   placeholder="000-000"
                                                                   defaultValue={Lg_('auth-id') === '5' ? 'скрыто для вас' : popClient.fiz[5]}
                                                            />
                                                        </div>                          {/* Подразделение */}
                                                        <div className="param">
                                                            <p>Паспорт - Дата выдачи</p>
                                                            <input className="param-field" name="fiz_data" type="date" autoComplete="off"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.fiz[6]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* Дата выдачи */}
                                                        <div className="param param-image">
                                                            <p>Изображение подписи{isPage === 'client' ? ' (не обязательно) для дистанционного подписания договора' : ''}</p>
                                                            {popClient.fiz[8] && Lg_('auth-id') !== '5'
                                                                ?   <div className="param-image-wrp">
                                                                    <img src={popClient.fiz[8]} alt=""/>
                                                                    <p className="param-image-del"
                                                                       onClick={()=>deleteImageFromClient('fiz_sign', 'fiz', 8)}
                                                                    >удалить</p>
                                                                </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name="fiz_sign" type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInClient(e.target, 'fiz', 8)}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>        {/* Подпись */}
                                                    </div>
                                                    <div className="popup-body-box popup-client-ur">
                                                        {Lg_('auth-id') === '5'
                                                            ? <div className="param param-line3"><p style={{color: 'red'}}>Важные данные скрыто для вас</p></div>
                                                            : ''
                                                        }
                                                        <div className="param">
                                                            <p>Правовая форма юр.лица</p>
                                                            <input className="param-field" name="company_form" type="text" autoComplete="off"
                                                                   placeholder="ООО"
                                                                   defaultValue={popClient.ur[1]}
                                                            />
                                                        </div>                          {/* Правовая форма */}
                                                        <div className="param">
                                                            <p>Название юр.лица</p>
                                                            <input className="param-field" name="company_name" type="text" autoComplete="off"
                                                                   placeholder="Название компании"
                                                                   defaultValue={popClient.ur[2]}
                                                            />
                                                        </div>                          {/* Название фирмы */}
                                                        <div className="param">
                                                            <p>Фамилия Имя Отчество директора</p>
                                                            <input className="param-field" name="company_director" type="text" autoComplete="off"
                                                                   placeholder="Иванов Иван Иванович"
                                                                   defaultValue={popClient.ur[11]}
                                                            />
                                                        </div>                          {/* ФИО директора */}
                                                        <div className="param param-line2">
                                                            <p>Адрес юр.лица</p>
                                                            <input className="param-field" name="company_address" type="text" autoComplete="off"
                                                                   placeholder="630007, Новосибирская обл., г.Новосибирск, ул.Ленина, д.99, оф.99, эт.9"
                                                                   defaultValue={popClient.ur[3]}
                                                            />
                                                        </div>          {/* Юр адрес */}
                                                        <div className="param">
                                                            <p>Фамилия И.О. директора (расшифровка подписи)</p>
                                                            <input className="param-field" name="company_director2" type="text" autoComplete="off"
                                                                   placeholder="Иванов И.И."
                                                                   defaultValue={popClient.ur[18]}
                                                            />
                                                        </div>                          {/* Расшифровка директора */}
                                                        <div className={`param param-line3 ${popClientCur === 'client'
                                                            ?   'param-hidden'
                                                            :   ''
                                                        }`}>
                                                            <p>Адрес офиса компании</p>
                                                            <input className="param-field" name="office_addr" type="text" autoComplete="off"
                                                                   placeholder="г.Новосибирск, ул.Серебренниковская, д.6/1, оф.12"
                                                                   defaultValue={popClient.ur[19]}
                                                            />
                                                        </div>      {/* Офис адрес */}
                                                        <div className="param">
                                                            <p>ИНН</p>
                                                            <input className="param-field" name="company_inn" type="number" autoComplete="off"
                                                                   placeholder="1234567890"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.ur[4]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* ИНН */}
                                                        <div className="param">
                                                            <p>КПП</p>
                                                            <input className="param-field" name="company_kpp" type="number" autoComplete="off"
                                                                   placeholder="123456789"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.ur[5]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* КПП */}
                                                        <div className="param">
                                                            <p>ОГРН</p>
                                                            <input className="param-field" name="company_ogrn" type="number" autoComplete="off"
                                                                   placeholder="1234567890123"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.ur[17]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* ОГРН */}
                                                        <div className="param param-line2">
                                                            <p>Банк</p>
                                                            <input className="param-field" name="company_bank" type="text" autoComplete="off"
                                                                   placeholder="ФИЛИАЛ НОВОСИБИРСКИЙ АО АЛЬФА-БАНК г.Новосибирск"
                                                                   defaultValue={popClient.ur[6]}
                                                            />
                                                        </div>          {/* Банк */}
                                                        <div className="param">
                                                            <p>Фамилия И.О. бухгалтера (расшифровка подписи)</p>
                                                            <input className="param-field" name="company_buhg" type="text" autoComplete="off"
                                                                   placeholder="Иванов И.И."
                                                                   defaultValue={popClient.ur[12]}
                                                            />
                                                        </div>                          {/* Расшифровка бухгалтер */}
                                                        <div className="param">
                                                            <p>БИК банка</p>
                                                            <input className="param-field" name="company_bik" type="number" autoComplete="off"
                                                                   placeholder="123456789"
                                                                   defaultValue={popClient.ur[7]}
                                                            />
                                                        </div>                          {/* БИК банка */}
                                                        <div className="param">
                                                            <p>Корреспондентский счет банка</p>
                                                            <input className="param-field" name="company_bank_rc" type="number" autoComplete="off"
                                                                   placeholder="12345678901234567890"
                                                                   defaultValue={popClient.ur[8]}
                                                            />
                                                        </div>                          {/* К/С */}
                                                        <div className="param">
                                                            <p>Расчётный счёт юр.лица в банке</p>
                                                            <input className="param-field" name="company_rc" type="number" autoComplete="off"
                                                                   placeholder="12345678901234567890"
                                                                   defaultValue={Lg_('auth-id') === '5' ? '' : popClient.ur[9]}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>                          {/* Р/С */}
                                                        <div className={`param param-line3 ${
                                                            popClientCur === 'client'
                                                                ? 'param-hidden'
                                                                : ''
                                                        }`}>
                                                            <p>Ссылка для перерода на Р/С юр.лица в банке (будет сгенерирован QR-код)</p>
                                                            <input className="param-field" name="company_rc_qr" type="text" autoComplete="off"
                                                                   placeholder="Ссылка при переходе по которой останется ввести только сумму..."
                                                                   defaultValue={popClient.ur[10]}
                                                            />
                                                        </div>      {/* QR */}
                                                        <div className="param param-image">
                                                            <p>Изображение подписи директора</p>
                                                            {popClient.ur[13] && Lg_('auth-id') !== '5'
                                                                ?   <div className="param-image-wrp">
                                                                    <img src={popClient.ur[13]} alt=""/>
                                                                    <p className="param-image-del"
                                                                       onClick={()=>deleteImageFromClient('company_director_sign', 'ur', 13)}
                                                                    >удалить</p>
                                                                </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name="company_director_sign" type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInClient(e.target, 'ur', 13)}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>          {/* Подпись директора */}
                                                        <div className="param param-image">
                                                            <p>Изображение подписи бухгалтера</p>
                                                            {popClient.ur[14] && Lg_('auth-id') !== '5'
                                                                ?   <div className="param-image-wrp">
                                                                    <img src={popClient.ur[14]} alt=""/>
                                                                    <p className="param-image-del"
                                                                       onClick={()=>deleteImageFromClient('company_buhg_sign', 'ur', 14)}
                                                                    >удалить</p>
                                                                </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name="company_buhg_sign" type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInClient(e.target, 'ur', 14)}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>          {/* Подпись бухгалтера */}
                                                        <div className="param param-image">
                                                            <p>Изображение печати компании</p>
                                                            {popClient.ur[15] && Lg_('auth-id') !== '5'
                                                                ?   <div className="param-image-wrp">
                                                                    <img src={popClient.ur[15]} alt=""/>
                                                                    <p className="param-image-del"
                                                                       onClick={()=>deleteImageFromClient('company_stamp', 'ur', 15)}
                                                                    >удалить</p>
                                                                </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name="company_stamp" type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInClient(e.target, 'ur', 15)}
                                                            />
                                                            {Lg_('auth-id') === '5'
                                                                ? <span style={{color: 'red'}}>скрыто для вас</span>
                                                                : ''
                                                            }
                                                        </div>          {/* Печать */}
                                                        <div className={`param param-image ${
                                                            isPage === 'client'
                                                                ? 'param-hidden'
                                                                : ''
                                                        }`}>
                                                            <p>Логотип компании</p>
                                                            {popClient.ur[16]
                                                                ?   <div className="param-image-wrp">
                                                                    <img src={popClient.ur[16]} alt=""/>
                                                                    <p className="param-image-del"
                                                                       onClick={()=>deleteImageFromClient('company_logo', 'ur', 16)}
                                                                    >удалить</p>
                                                                </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name="company_logo" type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInClient(e.target, 'ur', 16)}
                                                            />
                                                        </div>      {/* Logo */}
                                                    </div>
                                                    <div className="popup-body-box popup-client-gos">
                                                        <div className="param param-line3">
                                                            <p>Название учереждения</p>
                                                            <input className="param-field" name="gos_name" type="text" autoComplete="off"
                                                                   placeholder="ФГБУ Российская государственная библиотека"
                                                                   defaultValue={popClient.gos[1]}
                                                            />
                                                        </div>          {/* Название */}
                                                        <div className="param param-line3">
                                                            <p>Адрес учереждения</p>
                                                            <input className="param-field" name="gos_address" type="text" autoComplete="off"
                                                                   placeholder="630007, Новосибирская обл., г.Новосибирск, ул.Ленина, д.99"
                                                                   defaultValue={popClient.gos[2]}
                                                            />
                                                        </div>          {/* Адрес */}
                                                    </div>
                                                    <div className="popup-client-bottom">
                                                        <div className="popup-client-close" onClick={() => {
                                                            if(Lg_('auth-id') === '5'){
                                                                openMessage('','') // if guest account
                                                            }else{
                                                                // close and save
                                                                Q_('.popup-client').style.display = 'none'
                                                                saveClient()
                                                            }
                                                        }}>
                                                            {popClient.id === 'new'
                                                                ?   'Создать'
                                                                :   'Сохранить'
                                                            }
                                                        </div>
                                                        { isAuth && popClientCur === 'client' && isPage !== 'client'
                                                            ?   popClient.osn[5]
                                                                ?   <div className="popup-client-link">
                                                                    <span>Ссылка на редактирование клиента: </span>
                                                                    <Link to={`/clients/${popClient.osn[5]}`}>{`https://cabinet.zabor-knx.ru/clients/${popClient.osn[5]}`}</Link>
                                                                </div>
                                                                :   <div className="popup-client-link">
                                                                    <span>Ссылка для редактирование клиента появится после создания!</span>
                                                                </div>
                                                            :   ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="popup-close" onClick={() => {
                                                    // close and reset popup data
                                                    Q_('.popup-client').style.display = 'none'
                                                    setPopClient(popClientBlank)
                                            }}>×</div>
                                        </div>
                                    :   ''
                                }
                            </div>
                            <div className="popup popup-instock">
                                {popStock
                                    ?   <div className="popup-wrp-dad">
                                            <div className="popup-wrp popup-instock-wrp">
                                                <p className="popup-tit">
                                                    Наличие на складах
                                                    <span>{isSysDat.nomenclature[popStock.id].name}</span>
                                                </p>
                                                <div className="popup-instock-stocks">
                                                    {Object.entries(isSysDat.companies as Record<number, Company>).filter(el => el[1].osn[1] === true && el[1].osn[7] === true).map((co)=>(
                                                        <div className="param" key={co[1].id}>
                                                            <p>{co[1].nm} <span>- {isSysDat.cities[co[1].osn[2] as any]}</span></p>
                                                            <input type="number" data-id={co[1].id} autoComplete="off" placeholder="0" min={0}
                                                                defaultValue={
                                                                    popStock[co[1].id] && popStock[co[1].id] > 0
                                                                    ?   popStock[co[1].id]
                                                                    :   ''
                                                            } />
                                                                <div className="param-unit">{isSysDat.nomenclature[popStock.id].unit}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className="popup-instock-help">Настроить кто из поставщиков является "Складом" - можно в свойствах каждого из них, в разделе "Поставщики".</p>
                                                <div className="popup-instock-close" onClick={async () => {
                                                    if(Lg_('auth-id') === '5'){
                                                        openMessage('','') // if guest account
                                                        return
                                                    }

                                                    // collect data
                                                    let finishLine = ''
                                                    Qs_('.popup-instock .popup-instock-stocks .param input').forEach((inn)=>{
                                                        let inp = inn as HTMLInputElement
                                                        if(inp.value && +inp.value > 0){
                                                            let tmpStr = inp.getAttribute('data-id') + ':' + inp.value
                                                            if(finishLine){
                                                                finishLine += '_' + tmpStr
                                                            }else{
                                                                finishLine += tmpStr
                                                            }
                                                        }
                                                    })

                                                    // save data
                                                    let respoX = await Loadit.saveStock(popStock.id, finishLine)
                                                    Q_('.popup-wait').style.display = 'none'

                                                    let tmpSys = JSON.parse(JSON.stringify(isSysDat))
                                                    tmpSys.nomenclature[popStock.id].s = finishLine
                                                    setIsSysDat(tmpSys)

                                                    // close and reset pop data
                                                    Q_('.popup-instock').style.display = 'none'
                                                    setPopStock('')
                                                }}>Сохранить</div>
                                            </div>
                                            <div className="popup-close" onClick={() => {
                                                Q_('.popup-instock').style.display = 'none'
                                                setPopStock('')
                                            }}>×</div>
                                        </div>
                                    :   ''
                                }
                            </div>
                            <div className="popup popup-wait">
                                <div className="popup-wrp popup-wait-wrp">Ожидайте...</div>
                            </div>
                            <div className="popup popup-message">
                                <div className="popup-wrp-dad">
                                    <div className="popup-wrp">
                                        <p className="popup-tit">Заголовок</p>
                                        <div id="mess-txt">Текст сообщения</div>
                                    </div>
                                    <div className="popup-close" onClick={() => {Q_('.popup-message').style.display = 'none'}}>×</div>
                                </div>
                            </div>
                            <div className="popup popup-sure">
                                <div className="popup-wrp-dad">
                                    <div className="popup-wrp">
                                        <p className="popup-tit">Удалить безвозвратно?</p>
                                        <p className="popup-sure-alert">
                                            Учтите, что при удалении заказа, также удалятся все вложенные подзаказы поставщикам!
                                        </p>
                                        <div className="popup-sure-btns">
                                            <div className="popup-sure-btns-delete" data-num="0" data-type="" onClick={(e) => {
                                                let curTarget = e.target as HTMLElement
                                                if(curTarget.getAttribute('data-type') === 'order'){
                                                    deleteOrderSure()
                                                }else if(curTarget.getAttribute('data-type') === 'client'){
                                                    deleteClientSure()
                                                }else if(curTarget.getAttribute('data-type') === 'kp_pics'){
                                                    deleteKPpicSure()
                                                }else if(curTarget.getAttribute('data-type') === 'kp_texts'){
                                                    deleteKPtxtSure()
                                                }else if(curTarget.getAttribute('data-type') === 'kp_sets'){
                                                    deleteKPsetSure()
                                                }
                                                Q_('.popup-sure-alert').style.display = 'none'
                                            }}>Удалить</div>
                                            <div onClick={() => {
                                                    Q_('.popup-sure').style.display = 'none'
                                                    Q_('.popup-sure-alert').style.display = 'none'
                                                }}
                                            >Передумал</div>
                                        </div>
                                    </div>
                                    <div className="popup-close" onClick={() => {
                                            Q_('.popup-sure').style.display = 'none'
                                            Q_('.popup-sure-alert').style.display = 'none'
                                        }}
                                    >×</div>
                                </div>
                            </div>
                        </div>
                    : ''
                }
                <div className={`content ${'content-'+isPage}`} style={isPage === 'client' ? {display: 'none'} : {}}>
                    {isAuth && isSysDat.st && isPage !== 'client'
                        ?   <div className="content-head">
                                <Link to="/" className="content-head-logo"
                                      data-pop={popWasOpen}

                                      data-page={isPage}

                                      data-step={isHideOldChecked}
                                      data-city={filterCity}
                                      data-client={filterClient}
                                      data-partner={filterPartner}
                                      data-worker={filterWorker}

                                      data-pa-type={filterPaType}
                                      data-pa-step={filterPaStep}
                                      data-pa-city={filterPaCity}
                                      data-pa-client={filterPaClient}
                                      data-pa-partner={filterPaPartner}
                                      data-pa-worker={filterPaWorker}
                                >
                                    <span></span>
                                </Link>
                                { !isLandscape
                                    ?   <div className="content-head-burger" onClick={() => {
                                            Q_('.content-head-menu').classList.add('active')
                                            Q_('.content-head-who').classList.add('active')
                                            Q_('.content-head').classList.add('active')
                                        }}> </div>
                                    :   ''
                                }
                                <div className="content-head-menu">
                                    { !isLandscape
                                        ?   <div className="content-head-menu-close" onClick={()=>closeMobMenu()}>×</div>
                                        :   ''
                                    }
                                    <Link className={`content-head-menu-orders ${isPage === 'orders' || isPage === 'order' ? 'active' : ''}`}
                                          onClick={()=>closeMobMenu()} to="/orders"
                                    >Заказы клиентов</Link>
                                    <Link className={`content-head-menu-orders ${isPage === 'pa_orders' || isPage === 'pa_order' ? 'active' : ''}`}
                                          onClick={()=>closeMobMenu()} to="/pa-orders"
                                    >Заказы поставщикам</Link>
                                    <Link className={isPage === 'clients' || isPage === 'client' ? 'active' : ''}
                                          onClick={()=>closeMobMenu()} to="/clients"
                                    >Клиенты</Link>
                                    <Link className={isPage === 'partners' ? 'active' : ''}
                                          onClick={()=>closeMobMenu()} to="/partners"
                                    >Поставщики</Link>
                                    <Link className={isPage === 'stock' ? 'active' : ''}
                                          onClick={()=>closeMobMenu()} to="/stock"
                                    >Склад</Link>
                                </div>
                                <div className="content-head-who">
                                    <div className="content-head-who-pic"> </div>
                                    <div className="content-head-who-txt">
                                        <p>{Lg_('auth-name')} {Lg_('auth-surname')}</p>
                                        {Lg_('auth-role')}
                                        {!isLandscape
                                            ?   <div className="content-head-who-out" onClick={() => logoutUser()}>Выйти</div>
                                            :   ''
                                        }
                                    </div>
                                    {isLandscape
                                        ?   <div className="content-head-who-out" onClick={() => logoutUser()}> </div>
                                        :   ''
                                    }
                                </div>
                            </div>
                        :   ''
                    }
                    <div className="content-body">
                        <AppRouter/>
                    </div>
                    {Lg_('auth-id') === '5'
                        ?   <div className="content-footer">
                                <Link className="content-footer-creator" to="https://samborsky.pro" target="_blank">
                                    Разработка и поддержка:<img src="/sr.png" alt=""/>
                                </Link>
                            </div>
                        :   ''
                    }
                </div>
            </BrowserRouter>
        </AuthContext.Provider>
    )
}

export default App 