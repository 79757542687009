import axios from "axios";
let addrOrders = 'https://cabinet-control.zabor-knx.ru/orders/';
let addrSubOrders = 'https://cabinet-control.zabor-knx.ru/suborders/';
let addrClients = 'https://cabinet-control.zabor-knx.ru/clients/';
let addrDocs = 'https://cabinet-control.zabor-knx.ru/docs/';
let addrNoms = 'https://cabinet-control.zabor-knx.ru/noms/';

interface PswdObjType {
    password: string | null;
}

export default class Loadit {
    private static showPopupWait(): void {
        const popupWait = document.querySelector('.popup-wait') as HTMLElement | null
        if (popupWait) {
            popupWait.style.display = 'grid'
        }
    }

    // system
    static async getSystem(): Promise<any>{
        try {
            const response = await axios.get(addrOrders + '?sysdat=1')
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    static async getLogin(name: string, pswd: string): Promise<any>{
        try {
            this.showPopupWait()
            const userData: PswdObjType = {
                password: pswd,
            }
            const response = await axios.post(
                addrOrders + '?user2=' + name,
                JSON.stringify(userData),
            )
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    static async autoLoginCheck(name: string | null, pswd: string | null): Promise<any>{
        try {
            this.showPopupWait()
            const userData: PswdObjType = {
                password: pswd,
            }
            const response = await axios.post(
                addrOrders + '?user1=' + name,
                JSON.stringify(userData),
            )
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    // orders
    static async getOrders(str: any, count: any, offset: any, city: any, step: any, client: any, partner: any, worker: any): Promise<any>{
        try {
            let cityParam = ''
            if(localStorage.getItem('auth-role-cities') && localStorage.getItem('auth-role-cities') === '1'){
                cityParam = '&city='+localStorage.getItem('auth-city-id')
            }else if(city){
                cityParam = '&city='+city
            }

            let tmpStep = ''
            if(step){
                tmpStep = '&getstep='+step
            }

            let tmpClient = ''
            if(client){
                tmpClient = '&getclient='+client
            }

            let tmpPartner = ''
            if(partner){
                tmpPartner = '&getpartner='+partner
            }

            let tmpWorker = ''
            if(worker){
                tmpWorker = '&getworker='+worker
            }

            let tmpCount = ''
            if(count){
                tmpCount = '&getcount=' + count
            }

            let tmpOffset = ''
            if(offset){
                tmpOffset = '&getoffset=' + offset
            }

            const response = await axios.get(addrOrders + '?get=' + str + tmpCount + tmpOffset + cityParam + tmpStep + tmpClient + tmpPartner + tmpWorker)

            // send data to console for workers
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------ORDERS:')
                console.log(response.data)
            }

            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    static async getOneOrder(id: string): Promise<any>{
        try {
            const response = await axios.get(addrOrders + '?getone=' + id)
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------ONE-ORDER:')
                console.log(response.data)
            }
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    static async saveOrder(id:string, obj:any, who:string): Promise<any>{
        try {
            this.showPopupWait()
            const saveData = obj
            const response = await axios.post(
                addrOrders + '?saveorder=' + id + '&who=' + who,
                JSON.stringify(saveData),
            )
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    static async checkOrder(str: any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrOrders + '?checkorder=' + str)
            return response.data
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
    // suborders
    static async createSubOrder(who:any, papa:any, papaRoot:any, clone:any, sbType:any): Promise<any>{
        try {
            this.showPopupWait()

            let tmpClone = ''
            if(clone){
                tmpClone = '&doclone=1'
            }

            let tmpType = 1
            if(sbType){
                tmpType = sbType
            }

            const response = await axios.get(addrSubOrders + '?create=1&who=' + who + '&papa=' + papa + '&paparoot=' + papaRoot + tmpClone + '&sbtype=' + tmpType)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async getPaOrders(type:any, count:any, offset:any, city:any, step:any, client:any, partner:any, worker:any): Promise<any>{
        try {
            let cityParam = ''
            if(localStorage.getItem('auth-role-cities') && localStorage.getItem('auth-role-cities') === '1'){
                cityParam = '&city='+localStorage.getItem('auth-city-id')
            }else if(city){
                cityParam = '&city='+city
            }

            let tmpStep = ''
            if(step){
                tmpStep = '&getstep='+step
            }

            let tmpClient = ''
            if(client){
                tmpClient = '&getclient='+client
            }

            let tmpPartner = ''
            if(partner){
                tmpPartner = '&getpartner='+partner
            }

            let tmpWorker = ''
            if(worker){
                tmpWorker = '&getworker='+worker
            }

            let tmpCount = ''
            if(count){
                tmpCount = '&getcount=' + count
            }

            let tmpOffset = ''
            if(offset){
                tmpOffset = '&getoffset=' + offset
            }

            const response = await axios.get(addrSubOrders + '?get=' + type + tmpCount + tmpOffset + cityParam + tmpStep + tmpClient + tmpPartner + tmpWorker)

            // send data to console for workers
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------PA-ORDERS:')
                console.log(response.data)
            }

            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async getSubOrder(str:any): Promise<any>{
        try {
            const response = await axios.get(addrSubOrders + '?getone=' + str)
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------SUBORDER:')
                console.log(response.data)
            }
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async saveSubOrder(id:string, obj:any): Promise<any>{
        try {
            this.showPopupWait()
            const saveData = obj
            const response = await axios.post(
                addrSubOrders + '?saveorder=' + id,
                JSON.stringify(saveData),
            )
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    // orders & suborders
    static async cloneOrder(str:string): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrOrders + '?clone=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async deleteOrder(str:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrOrders + '?delete=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }

    // clients
    static async saveClient(id:string, obj:any, who:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.post(addrClients + '?saveclient=' + id + '&who=' + who, obj, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
                }
            })
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async deleteClient(str:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrClients + '?delclient=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }

    // stock
    static async getOneNom(id:string): Promise<any>{
        try {
            this.showPopupWait()
            const resp = await axios.get(addrNoms + '?onenom=' + id)
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------ONE-NOM:')
                console.log(resp.data)
            }
            return resp.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async saveStock(id:string, str:string): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.post(addrNoms + '?savestock=' + id, str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async getHelp(): Promise<any>{
        try {
            const resp = await axios.get(addrNoms + '?help=1')
            if(localStorage.getItem('auth') && localStorage.getItem('auth-id') === '1'){
                console.log('----------------------HELP:')
                console.log(resp.data)
            }
            return resp.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }

    // KP
    static async getKP_texts(): Promise<any>{
        try {
            const response = await axios.get(addrDocs + '?kp=1')
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async saveKP_text(id:string, arr:any): Promise<any>{
        try {
            this.showPopupWait()
            const saveData = arr
            const response = await axios.post(
                addrDocs + '?savekptxt=' + id,
                JSON.stringify(saveData),
            )
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async saveKP_pic(id:string, obj:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.post(addrDocs + '?savekppic=' + id, obj, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
                }
            })
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async saveKP_set(obj:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.post(addrDocs + '?savekpset=1', obj, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${obj._boundary}`,
                }
            })
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async deleteKPpic(str:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrDocs + '?delkppic=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async deleteKPtxt(str:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrDocs + '?delkptxt=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
    static async deleteKPset(str:any): Promise<any>{
        try {
            this.showPopupWait()
            const response = await axios.get(addrDocs + '?delkpset=' + str)
            return response.data
        } catch (error) {
            console.error("Error: ", error);
            throw error;
        }
    }
}