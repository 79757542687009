import React, {useContext} from 'react';
import {Routes, Route} from "react-router-dom";
import PgOrders from "./pg_Orders";
import PgPaOrders from "./pg_PaOrders";
import PgOrder from "./pg_Order";
import PgLogin from "./pg_Login";
import PgClients from "./pg_Clients";
import PgClient from "./pg_Client";
import PgPartners from "./pg_Partners";
import PgStock from "./pg_Stock";
import {AuthContext} from '../context/Context'

const AppRouter: React.FC = () => {
    const context = useContext(AuthContext)
    if (!context){throw new Error("AuthContext must be used within an AuthProvider")}
    const {isAuth} = context

    return (
        isAuth
            ?
            <Routes>
                <Route path={'/'} element={<PgOrders/>} />
                <Route path={'/orders'} element={<PgOrders/>} />
                <Route path={'/pa-orders'} element={<PgPaOrders/>} />
                <Route path={'/orders/:id'} element={<PgOrder/>} />
                <Route path={'/clients'} element={<PgClients/>} />
                <Route path={'/clients/:id'} element={<PgClient/>} />
                <Route path={'/partners'} element={<PgPartners/>} />
                <Route path={'/stock'} element={<PgStock/>} />
            </Routes>
            :
            <Routes>
                <Route path={'/login'} element={<PgLogin/>} />
                <Route path={'/clients/:id'} element={<PgClient/>} />
                <Route path={'*'} element={<PgLogin/>} />
            </Routes>
    )
}

export default AppRouter