import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {AuthContext} from "../context/Context";
import IcoTrash from "./IcoTrash";
import IcoClone from "./IcoClone";

const OneSubOrder = (props) => {
    // states and vars
    const {Lg_, isSysDat, isPrices, isLandscape, deleteOrder, openMessage} = useContext(AuthContext)
    const [someIn] = useState(props.suborders.filter(sb => sb.pr == props.thisOrder.id))

    return (
        <div className="one-suborder-wrp" data-id={props.thisOrder.id}>
            <Link className={`one one-suborder ${props.thisOrder.id == props.curOrder && !props.fromOrderList ? 'active' : ''} ${props.notSub || props.thisOrder.sb == 2 ? 'main' : ''}`}
                  to={`/orders/${props.notSub ? '' : 'p'}${props.thisOrder.id}`}
                  onClick={() => {
                      if(!props.fromOrderList){
                          props.loadThisOrder(`${props.notSub ? '' : 'p'}`+props.thisOrder.id)
                          props.setOrder({})
                          props.setSuborders([])
                          props.setIsContent([])

                          props.setCurIspo(props.thisOrder.ispo)
                          props.setCurStep(props.thisOrder.st)
                          props.setCurDtPlan(props.thisOrder.plan)

                          props.setChanges(false)
                          props.setSaving(false)
                      }
                  }}
            >
                {someIn.length > 0
                    ?   <div className="one-suborder-arr" onClick={(e)=>{
                            e.preventDefault()
                            e.stopPropagation()
                            let tmpEl = e.target.closest('.one-suborder-wrp')
                            if(tmpEl.classList.contains('collapsed')){
                                tmpEl.classList.remove('collapsed')
                            }else{
                                tmpEl.classList.add('collapsed')
                            }
                        }}> </div>
                    :   <div className="one-suborder-arr2"> </div>
                }
                <p>
                    <span>
                        {props.notSub
                            ?   'Основной заказ от клиента'
                            :   props.thisOrder.sb == 1
                                ?   'Заявка поставщику'
                                :   'Заказ покупателя'
                        } (
                    </span>
                    id:{props.thisOrder.id}
                    <span>) от {props.thisOrder.cr},</span>
                </p>
                <p>
                    <span>Исполнитель: </span>
                    {isLandscape ? '' : '- исп.: '}
                    {props.thisOrder.id == props.curOrder && props.curIspo
                        ?   props.curIspo == 3059
                            ?   'Сторонний поставщик'
                            :   isSysDat.companies[props.curIspo].nm
                        :   props.thisOrder.ispo
                            ?   props.thisOrder.ispo == 3059
                                ?   'Сторонний поставщик'
                                :   isSysDat.companies[props.thisOrder.ispo].nm
                            :   'Не указан'
                    }
                    <span>,</span>
                </p>
                {props.thisOrder.id == props.curOrder && props.curStep
                    ?   <p className="light">{isSysDat.st[props.curStep]}<span>,</span></p>
                    :   props.thisOrder.st
                        ?   <p className="light">{isSysDat.st[props.thisOrder.st]}<span>,</span></p>
                        :   ''
                }
                <p className="light">
                    <span>Дата план.: </span>
                    {props.thisOrder.id == props.curOrder && props.curDtPlan
                        ?   props.curDtPlan.replace('T', ' в ')
                        :   props.thisOrder.plan
                            ?   props.thisOrder.plan.replace('T', ' в ')
                            :   'Нет'
                    }
                    <span>,</span>
                </p>
                <p className="light">
                    <span>Цена: </span>
                    {parseFloat((
                            props.thisOrder.id == props.curOrder
                                ? props.curSum
                                : props.thisOrder.pc[props.thisOrder.pt ? props.thisOrder.pt-1 : isPrices - 1]*1
                        ).toFixed(0)).toLocaleString('ru-RU')} р
                </p>
                {props.thisOrder.id == props.curOrder && !props.fromOrderList
                    ?   <strong>{'<'} Вы сейчас тут</strong>
                    :   ''
                }
                {props.fromOrderList || props.thisOrder.sb == 1 && someIn.length > 0
                    ?   ''
                    :   <div className="one-suborder-addsub" data-tooltip={`Создать ${props.thisOrder.sb == 2 ? 'заявку поставщику' : 'заказ покупателя'}`}
                             onClick={(e) => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 props.createSubOrder(props.thisOrder.id, false, props.thisOrder.sb != 1 ? 1 : 2)
                             }}
                        >+</div>
                }
                {props.notSub || props.fromOrderList || props.thisOrder.sb == 2
                    ?   ''
                    :   <div className="one-suborder-clone" data-tooltip="Создать дубль"
                           onClick={(e) => {
                               e.preventDefault()
                               e.stopPropagation()
                               props.createSubOrder(props.thisOrder.id, true, 1)
                           }}
                    ><IcoClone/></div>
                }
                {props.notSub || props.fromOrderList
                    ?   ''
                    :   <div className="one-suborder-delete" data-tooltip="Удалить" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            deleteOrder(props.thisOrder.id)
                        }}><IcoTrash/></div>
                }
            </Link>
            {someIn.length > 0
                ?   <div className="one-suborder-in">
                        {someIn.map((sub)=>(
                            <OneSubOrder thisOrder={sub} key={sub.id}
                                         fromOrderList={props.fromOrderList}
                                         curOrder={props.curOrder}
                                         curSum={props.curSum}
                                         curIspo={props.curIspo} setCurIspo={props.setCurIspo}
                                         curStep={props.curStep} setCurStep={props.setCurStep}
                                         curDtPlan={props.curDtPlan} setCurDtPlan={props.setCurDtPlan}
                                         setOrder={props.setOrder}
                                         suborders={props.suborders} setSuborders={props.setSuborders}
                                         setIsContent={props.setIsContent}
                                         setChanges={props.setChanges}
                                         setSaving={props.setSaving}
                                         loadThisOrder={props.loadThisOrder}
                                         createSubOrder={props.createSubOrder}
                            />
                        ))}
                    </div>
                :   ''
            }
        </div>
    );
};

export default OneSubOrder;