import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/Context";
import OneNom from "../components/OneNom";
import Loadit from "../funcs/Load";
import OneHelp from "../components/OneHelp";
import IcoTrash from "../components/IcoTrash";
import IcoGear from "../components/IcoGear";
import IcoPlus from "../components/IcoPlus";
import {string} from "mathjs";

interface popNomEdit {
    ca?: any | null
    id?: any | null
    in?: any | null
    in_pr?: any | null
    nm?: any | null
    nopa?: any | null
    pc?: Array<any | null>
    pg?:  any | null
    pic?: Array<any | null>
    pr?: any | null
    pr_lg?: any | null
    rd?: any | null
    ut?: any | null
    [key: string]: any | null
}

const PgStock: React.FC = () => {
    // states and vars
    const {Q_, setIsPage, isSysDat, isLandscape, openMessage} = useContext(AuthContext)
    const [sysCats, setSysCats] = useState<any>('')
    const [sysNoms, setSysNoms] = useState({})
    const [filterCat, setFilterCat] = useState<string>('')
    const [filterStock, setFilterStock] = useState('')
    // pops
    const [popNomEdit, setPopNomEdit] = useState<popNomEdit>({})
    const [popNomHelp, setPopNomHelp] = useState(()=>{
        let st = 'Не удалось загрузить инструкции, обновите страницу, чтобы исправить это.'
        return [st,st,st,st,st,st,st,st]
    })


    // ones for first load and for closing
    // load instructions for noms fields
    async function getNomsHelp() {
        let resp = await Loadit.getHelp()
        setPopNomHelp(resp)
    }
    useEffect(() => {
        // set current menu page
        setIsPage('stock')

        // load instructions for noms fields
        getNomsHelp()
    }, [])

    // data for manupilation
    useEffect(() => {
        if(isSysDat){
            if(isSysDat.cats){
                let tpCt = JSON.parse(JSON.stringify(isSysDat.cats))
                tpCt[1] = "Без категории"
                setSysCats(tpCt)

                setSysNoms(JSON.parse(JSON.stringify(isSysDat.nomenclature)))
            }
        }
    },[isSysDat])

    // filtring
    useEffect(() => {
        console.log(filterCat)
        if(isSysDat){
            if(isSysDat.cats){
                // cats
                if(filterCat){
                    if(filterCat === '1'){
                        setSysCats({
                            1: "Без категории"
                        })
                    }else{
                        let tmpCats = {} as any
                        tmpCats[filterCat] = isSysDat.cats[filterCat]
                        setSysCats(tmpCats)
                    }
                }else{
                    let tpCt = JSON.parse(JSON.stringify(isSysDat.cats))
                    tpCt[1] = "Без категории"
                    setSysCats(tpCt)
                }

                // stocks
                if(filterStock){
                    setSysNoms({})
                    setTimeout(function () {
                        let tmpNoms = {} as any
                        Object.entries(isSysDat.nomenclature).forEach((nm : any)=>{
                            if(nm[1].s){
                                if(nm[1].s.indexOf(filterStock + ':') + 1){
                                    tmpNoms[nm[0]] = nm[1]
                                }
                            }
                        })
                        setSysNoms(tmpNoms)
                    },100)
                }else{
                    setSysNoms(JSON.parse(JSON.stringify(isSysDat.nomenclature)))
                }
            }
        }
    },[filterCat, filterStock])

    // load nomData for editing
    async function loadNomData(id : any) {
        let resp = await Loadit.getOneNom(id)
        setPopNomEdit(resp)
    }
    function deleteImageFromNom(inName : any, inNum : any){
        let tmpPopNom = JSON.parse(JSON.stringify(popNomEdit))
        tmpPopNom.pic[inNum] = ''
        setPopNomEdit(tmpPopNom)

        let tmpInpEl = Q_('.popup-nomedit input[name="'+inName+'"]')
        tmpInpEl.value = ''
        tmpInpEl.setAttribute('data-del', '1')
    }
    function changeImageInNom(inElm : any, inNum : number | string){
        let f = inElm.files[0]
        let fr = new FileReader()
        fr.onload = function(ev2) {
            let tmpPopNom : popNomEdit = JSON.parse(JSON.stringify(popNomEdit))
            if(tmpPopNom.pic && inNum && ev2.target){
                tmpPopNom.pic[+inNum] = ev2.target.result
                setPopNomEdit(tmpPopNom)
                inElm.setAttribute('data-del', '')
            }
        }
        fr.readAsDataURL(f)
    }
    // NomEdit popup tabs switcher
    function switchNomEditTabs(str : any){
        Q_('.popup-nomedit .popup-menu-one.active').classList.remove('active')
        Q_('.popup-nomedit .popup-body-box.active').classList.remove('active')
        Q_('.popup-nomedit .popup-menu-one.popup-nomedit-'+str).classList.add('active')
        Q_('.popup-nomedit .popup-body-box.popup-nomedit-'+str).classList.add('active')
    }

    return (
        <div className="page page-stock">
            {isSysDat.st
            ?   <div className="page-stock-wrp">
                    <div className="popups">
                        <div className="popup popup-nomedit">
                            {popNomEdit.id
                                ?   <div className="popup-wrp-dad">
                                        <div className="popup-wrp popup-nomedit-wrp">
                                            {!isLandscape
                                                ?   <p className="popup-tit">Редактор номенклатуры:</p>
                                                :   ''
                                            }
                                            <div className="popup-menu">
                                                <div className="popup-menu-one popup-nomedit-osn active"
                                                     onClick={()=>switchNomEditTabs('osn')}>Основное</div>
                                                <div className="popup-menu-one popup-nomedit-par"
                                                     onClick={()=>switchNomEditTabs('par')}>Параметры</div>
                                                <div className="popup-menu-one popup-nomedit-pic"
                                                     onClick={()=>switchNomEditTabs('pic')}>Чертежи</div>
                                                <div className="popup-menu-one popup-nomedit-ins"
                                                     onClick={()=>switchNomEditTabs('ins')}>Вложенные ном-ры</div>
                                                <div className="popup-menu-one popup-nomedit-cen"
                                                     onClick={()=>switchNomEditTabs('cen')}>Стоимость</div>
                                            </div>
                                            <div className="popup-body popup-nomedit-body">
                                                <div className="popup-body-box popup-nomedit-osn active">
                                                    <div className="param param-line3">
                                                        <p>Название номенклатуры</p>
                                                        <input className="param-field" name="title" type="text" autoComplete="off"
                                                           placeholder="Введите название номенклатуры"
                                                           defaultValue={popNomEdit.nm}
                                                        />
                                                    </div>                              {/* Name */}
                                                    <div className="param param-line2">
                                                        <p>Категория</p>
                                                        <select className="param-field" name="cat" autoComplete="off"
                                                                defaultValue={popNomEdit.ca}
                                                        >
                                                            <option value="">Категория не выбрана</option>
                                                            {Object.entries(isSysDat.cats).map((el: any) => (
                                                                <option value={el[0]} key={el[0]}>{el[1]}</option>
                                                            ))}
                                                        </select>
                                                    </div>                              {/* Cat */}
                                                    <div className="param">
                                                        <p>Единицы измерения</p>
                                                        <select className="param-field" name="nom_unit" autoComplete="off"
                                                            defaultValue={popNomEdit.ut}
                                                        >
                                                            { isSysDat.units.map((el : any) => (
                                                                <option value={el.id} key={el.id}>{el.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>                                              {/* Unit */}
                                                    <div className="param param-line3">
                                                        <input className="param-field" name="nom_round" type="checkbox" autoComplete="off"
                                                               id="nom_round" defaultChecked={popNomEdit.rd}
                                                        />
                                                        <label htmlFor="nom_round">Округлять итоговое количество до целых (в большую сторону)</label>
                                                    </div>                              {/* Do rounding? */}
                                                </div>
                                                <div className="popup-body-box popup-nomedit-par">
                                                    <div className="param param-line3">
                                                        <p>Список параметров для редактирования</p>
                                                        <OneHelp inTxt={popNomHelp[0]}/>
                                                        <textarea className="param-field" name="nom_params" autoComplete="off"
                                                              placeholder="Параметры не введены" defaultValue={popNomEdit.pr}
                                                        />
                                                    </div>                              {/* Parameters */}
                                                    <div className="param param-line3">
                                                        <p>Логика отображения параметров</p>
                                                        <OneHelp inTxt={popNomHelp[1]}/>
                                                        <textarea className="param-field" name="nom_params_logic" autoComplete="off"
                                                                  placeholder="Логика не введена" defaultValue={popNomEdit.pr_lg}
                                                        />
                                                    </div>                              {/* Logic of parameters */}
                                                    <div className="param param-line3">
                                                        <p>Погонажный коэффициент</p>
                                                        <OneHelp inTxt={popNomHelp[2]}/>
                                                        <input className="param-field" name="nom_pogonazh_logic" type="text" autoComplete="off"
                                                               placeholder="Погонажный коэффициент не введен"
                                                               defaultValue={popNomEdit.pg}
                                                        />
                                                    </div>                              {/* Linear coefficient */}
                                                </div>
                                                <div className="popup-body-box popup-nomedit-pic">
                                                    <p className="param-line3">Загруженные чертежи, вы можете использовать для отображения среди параметров номенклатуры.
                                                        При работе с заказами - это позволит легче понять какой параметр за что отвечает в реальном изделии или задаче.
                                                    </p>
                                                    {popNomEdit.pic ? popNomEdit.pic.map((pic, i)=>(
                                                        <div className="param param-image" key={i}>
                                                            <p>Изображение №{i+1}</p>
                                                            {pic
                                                                ?   <div className="param-image-wrp" data-name={`nom_pic_${i+1}`}>
                                                                        <img src={pic} alt=""/>
                                                                        <p className="param-image-del"
                                                                           onClick={()=>deleteImageFromNom(`nom_pic_${i+1}`, i)}
                                                                        >удалить</p>
                                                                    </div>
                                                                :   ''
                                                            }
                                                            <input className="param-field" name={`nom_pic_${i+1}`} type="file" autoComplete="off" data-del=""
                                                                   onChange={(e)=>changeImageInNom(e.target, i)}
                                                            />
                                                        </div>
                                                    )) : ''}
                                                </div>
                                                <div className="popup-body-box popup-nomedit-ins">
                                                    <div className="param param-line3">
                                                        <p>Вложенные номенклатуры и расчёт их количества</p>
                                                        <OneHelp inTxt={popNomHelp[3]}/>
                                                        <textarea className="param-field" name="nom_inside_count" autoComplete="off"
                                                                  placeholder="Вложенные номенклатуры не введены" defaultValue={popNomEdit.in}
                                                        />
                                                    </div>                              {/* Inside noms */}
                                                    <div className="param param-line3">
                                                        <p>Передача параметров во вложенную номенклатуру</p>
                                                        <OneHelp inTxt={popNomHelp[4]}/>
                                                        <textarea className="param-field" name="nom_inside_params" autoComplete="off"
                                                                  placeholder="Логика передача параметров не введена" defaultValue={popNomEdit.in_pr}
                                                        />
                                                    </div>                              {/* Parameters to Inside noms */}
                                                    <div className="param param-line3">
                                                        <OneHelp inTxt={popNomHelp[5]}/>
                                                        <input className="param-field" name="nom_inside_nopapa" type="checkbox" autoComplete="off"
                                                               id="nom_inside_nopapa" defaultChecked={popNomEdit.nopa}
                                                        />
                                                        <label htmlFor="nom_inside_nopapa">Не учитывать количество родителя у вложеной номенклатуры</label>
                                                    </div>                              {/* Do rounding? */}
                                                </div>
                                                <div className="popup-body-box popup-nomedit-cen">
                                                    <div className="param param-line3">
                                                        <p>Формула расчёта ЗАКУПОЧНОЙ стоимости</p>
                                                        <OneHelp inTxt={popNomHelp[6]}/>
                                                        <textarea className="param-field" name="nom_price_zacupo" autoComplete="off"
                                                                  placeholder="Формула не введена" defaultValue={popNomEdit.pc ? popNomEdit.pc[0] : null}
                                                        />
                                                    </div>                              {/* Zacupo */}
                                                    <div className="param param-line3">
                                                        <p>Формула расчёта ПАРТНЁРСКОЙ стоимости</p>
                                                        <textarea className="param-field" name="nom_price_partner" autoComplete="off"
                                                                  placeholder="Формула не введена" defaultValue={popNomEdit.pc ? popNomEdit.pc[1] : null}
                                                        />
                                                    </div>                              {/* Partner */}
                                                    <div className="param param-line3">
                                                        <p>Формула расчёта РОЗНИЧНАЯ стоимости</p>
                                                        <textarea className="param-field" name="nom_price_roznica" autoComplete="off"
                                                                  placeholder="Формула не введена" defaultValue={popNomEdit.pc ? popNomEdit.pc[2] : null}
                                                        />
                                                    </div>                              {/* Roznica */}
                                                    <div className="param param-line3">                                 {/* City */}
                                                        <p>Значение <span>city</span> по умолчанию, если для какого-то из городов она не задана</p>
                                                        <OneHelp inTxt={popNomHelp[7]}/>
                                                        <input type="text" data-id="0" autoComplete="off"
                                                               placeholder="Формула не введена" defaultValue="0"
                                                        />
                                                    </div>
                                                    {Object.entries(isSysDat.cities).map((ct : any)=>(
                                                        <div className="param param-line3" key={ct[0]}>
                                                            <p>Значение <span>city</span> для {ct[1].toUpperCase()}</p>
                                                            <input type="text" data-id={ct[0]} autoComplete="off"
                                                                   placeholder="Формула не введена"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="popup-nomedit-bottom">
                                                    <div className="popup-nomedit-close" onClick={() => {
                                                        // message
                                                        openMessage()

                                                        // close and save
                                                        Q_('.popup-nomedit').style.display = 'none'
                                                        setPopNomEdit({})
                                                    }}>Сохранить</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup-close" onClick={() => {
                                            // close and reset popup data
                                            Q_('.popup-nomedit').style.display = 'none'
                                            setPopNomEdit({})
                                        }}>×</div>
                                    </div>
                                :   ''
                            }
                        </div>
                    </div>
                    <div className="page-stock-head">
                        <div className="page-stock-head-wrp"></div>
                        <div className="page-stock-head-wrp">
                            <select id="stock-in" autoComplete="off" defaultValue={filterStock}
                                    className={`filter ${filterStock ? 'active' : ''}`}
                                    onChange={(e) => {
                                        setFilterStock(e.target.value)
                                    }}
                            >
                                <option value="">Фильтр по Наличию на складах</option>
                                {Object.entries(isSysDat.companies).filter((el : any) => el[1].osn[1] === true && el[1].osn[7] === true).map((co: any)=>(
                                    <option value={co[0]} key={co[0]}>{co[1].nm} - {isSysDat.cities[co[1].osn[2]]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="page-stock-head-wrp">
                            <select id="stock-cat" autoComplete="off" defaultValue={filterCat}
                                    className={`filter ${filterCat ? 'active' : ''}`}
                                    onChange={(e) => {
                                        setFilterCat(e.target.value)
                                    }}
                            >
                                <option value="">Фильтр по Категории</option>
                                {Object.entries(isSysDat.cats).map((el : any) => (
                                    <option value={el[0]} key={el[0]}>{el[1]}</option>
                                ))}
                                <option value="1">Без категории</option>
                            </select>
                        </div>
                    </div>
                    <div className="page-stock-list">
                        {sysCats
                            ?   Object.entries(sysCats).map((el : any) => {
                                    return(
                                        <div className="page-stock-cat" key={el[0]}>
                                            <p className="page-stock-cat-tit">{el[1]}</p>
                                            <div className="page-stock-cat-btns" onClick={(e : any)=>{
                                                e.preventDefault()
                                                e.stopPropagation()
                                                if(!isLandscape){
                                                    e.target.classList.add('active')
                                                }
                                            }}>
                                                <div className="page-stock-cat-new" data-tooltip="Создать новую номенклатуру"
                                                     onClick={(e)=>{
                                                         openMessage()
                                                     }}
                                                ><IcoPlus/></div>
                                                <div className="page-stock-cat-edit" data-tooltip="Редактор категории"
                                                     onClick={(e)=>{
                                                         openMessage()
                                                     }}
                                                ><IcoGear/></div>
                                                <div className="page-stock-cat-delete" data-tooltip="Удалить"
                                                     onClick={(ev) => {
                                                         openMessage()
                                                     }}
                                                ><IcoTrash/></div>
                                            </div>
                                            <div className="page-stock-cat-wrp">
                                                {Object.entries(sysNoms).filter(el[0] == 1 ? (no: any) => !no[1].ca : (no: any) => no[1].ca == el[0]).map((nom, i) => (
                                                    <OneNom thisNom={nom[1]} key={i} loadNomData={loadNomData}/>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })
                            :   ''
                        }
                        <div className="page-stock-cat-add"
                             onClick={(e)=>{
                                 openMessage()
                             }}
                        ><IcoPlus/> Добавить новую категорию</div>
                    </div>
                </div>
            :   <h6>
                    Загрузка номенклатур и системных данных, ожидайте...
                    <span>Если очень долго грузится, то нажмите обновить страницу</span>
                </h6>
            }
        </div>
    );
};

export default PgStock;