import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Получаем элемент root и проверяем его наличие
const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error("Root element not found. Check your HTML file.");
}

// Инициализируем React
const root = createRoot(rootElement);
root.render(<App />);
