import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/Context";
import OneOrder from "../components/OneOrder";
import Loadit from "../funcs/Load";

const PgPaOrders = () => {
    // states and vars
    const {Q_, Lg_,
        isSysDat,
        isPrices, setIsPrices,
        setIsPage,
        paOrders, setPaOrders,
        setSumReady,

        filterPaType, setFilterPaType,
        filterPaStep, setFilterPaStep,
        filterPaCity, setFilterPaCity,
        filterPaClient, setFilterPaClient,
        filterPaPartner, setFilterPaPartner,
        filterPaWorker, setFilterPaWorker,
    } = useContext(AuthContext)
    const [firstPageLoad, setFirstPageLoad] = useState(true)

    // ones for first load
    useEffect(() => {
        // set sumReady to TRUE when load this page
        setSumReady(true)

        // set current menu page
        setIsPage('pa_orders')
    }, [])

    // reload orders when toggle any filters
    async function toggleFilters() {
        // get and set
        let response = await Loadit.getPaOrders(filterPaType, 999, '', filterPaCity, filterPaStep, filterPaClient, filterPaPartner, filterPaWorker)
        setPaOrders(response)

        // show / hide more
        let elMore = Q_('.page-pa_orders-more')
        if(response.count < 100){
            if(elMore){
                elMore.classList.add('page-pa_orders-more-hide')
            }
            setTimeout(function () {
                if(Q_('.page-pa_orders-more')){
                    Q_('.page-pa_orders-more').classList.remove('page-pa_orders-more-hide')
                }
            },60000)
        }else{
            if(elMore){
                elMore.classList.remove('page-pa_orders-more-hide')
            }
        }

        // select filters if we come from PARTNERS
        Q_('#pa-orders-partner').value = filterPaPartner

        Q_('.popup-wait').style.display = 'none'
    }
    useEffect(() => {
        if(firstPageLoad){
            setFirstPageLoad(false)
        }else{
            toggleFilters()
        }
    },[filterPaType, filterPaStep, filterPaCity, filterPaClient, filterPaPartner, filterPaWorker])

    return (
        <div className="page page-pa_orders">
            { isSysDat.st
                ?   <div className="page-pa_orders-box">
                        <div className="page-pa_orders-head">
                            <div>
                                <select autoComplete="off" defaultValue={filterPaType} className="filter"
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPaType(e.target.value)
                                        }}
                                >
                                    <option value="1">Заявки поставщикам</option>
                                    <option value="2">Заказы покупателей</option>
                                </select>
                            </div>
                            <div>
                                <select autoComplete="off" defaultValue={filterPaStep}
                                        className={`filter ${filterPaStep ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPaStep(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Стадии</option>
                                    {Object.entries(isSysDat.st).map((el) => (
                                        <option value={el[0]} key={el[0]}>{el[1]}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select autoComplete="off" defaultValue={filterPaPartner} id="pa-orders-partner"
                                        className={`filter ${filterPaPartner ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPaPartner(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Исполнителю</option>
                                    {Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((el) => (
                                        <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select autoComplete="off" defaultValue={filterPaWorker}
                                        className={`filter ${filterPaWorker ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPaWorker(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Ответственному</option>
                                    { isSysDat.users.map((el) => (
                                        <option value={el.value} key={el.value}>{el.role}: {el.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select autoComplete="off" defaultValue={filterPaCity}
                                        className={`filter ${ Lg_('auth-role-cities')*1 === 1 ? 'hideWhileCalc' : ''} ${filterPaCity ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPaCity(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Городу</option>
                                    {Object.entries(isSysDat.cities).map((el) => (
                                        <option value={el[0]} key={el[0]}>{el[1]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="page-pa_orders-wrp">
                            {paOrders.count || paOrders.count === 0
                                ?   paOrders.count !== 0
                                    ?   paOrders.data.map((el, i) => (
                                        <OneOrder thisOrder={el} key={i}/>
                                    ))
                                    :   <h6>Заказов с такими параметрами не найдено<span>Либо у вас нет доступа к их просмотру</span></h6>
                                :   <h6>Загрузка заказов, ожидайте...<span>Если очень долго грузится, то нажмите обновить страницу</span></h6>
                            }
                        </div>
                    </div>
                :   <h6>
                        Загрузка номенклатур и системных данных, ожидайте...
                        <span>Если очень долго грузится, то нажмите обновить страницу</span>
                    </h6>
            }
        </div>
    );
};

export default PgPaOrders;