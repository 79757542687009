import React from 'react';

const IcoSaw = (props) => {
    return (
        <svg viewBox="0 0 771 782" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M771 263.094V239.005C771 186.469 753.69 135.397 721.748 93.6877L709.286 77.4146C671.915 28.6167 613.956 0 552.492 0C501.486 0 452.458 19.7342 415.674 55.0702L339.372 128.37C328.559 138.758 327.076 155.535 335.9 167.659L482.579 369.186C492.053 382.203 504.529 392.74 518.948 399.901L566.742 423.639C614.91 447.562 672.904 438.745 711.785 401.588C749.606 365.445 771 315.407 771 263.094ZM0 643.309V555.211C0 528.223 10.908 502.381 30.2459 483.556L320.5 201L456.418 391.195C466.626 405.48 480.415 416.825 496.398 424.09L516 433L506 513L360.5 497.5L350.5 646L206.5 633L193.5 781.5L79 765L28.411 713.13C10.1955 694.454 0 669.398 0 643.309ZM451.539 144.468L483.401 113.338C503.717 93.489 533.44 86.694 560.362 95.7435C570.666 99.2071 580.103 104.851 588.029 112.291L634.003 155.445C649.408 169.904 660.83 188.087 667.17 208.241L671.01 220.451C683.18 259.138 671.566 301.366 641.326 328.39C628.332 340.001 610.357 344.271 593.53 339.745L584.72 337.375C552.669 328.753 524.84 308.784 506.409 281.181L446.61 191.624C436.69 176.768 438.761 156.952 451.539 144.468Z"/>
        </svg>
    );
};

export default IcoSaw;