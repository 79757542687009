import React, {useContext} from 'react';
import {AuthContext} from "../context/Context";
import IcoPlus from "./IcoPlus";

const BlockAddElement = (props) => {
    const {Q_, isSysDat, sumReady} = useContext(AuthContext)

    return (
        <div className="add-wrp">
            {props.active
                ?   <div className={`param page-order-content-add ${props.from === 'element' || props.from === 'insided' ? 'inlink' : ''}`}
                         data-deep={props.deep}
                    >
                        <div>
                            <select className="page-order-content-add-cat" autoComplete="off" defaultValue="-"
                                    onChange={(e) => {
                                        props.setCat(e.target.value)
                                        props.setNom(0)
                                    }}>
                                <option value="-" disabled="on">Выберите категорию</option>
                                <option value="uni">Уникальная номенклатура</option>
                                {Object.entries(isSysDat.cats).map((el) => (
                                    <option value={el[0]} key={el[0]}>{el[1]}</option>
                                ))}
                                <option value="0">Без категории</option>
                            </select>
                        </div>
                        <div>
                            { props.isCat === '-'
                                ?   ''
                                :   props.isCat === 'wrp' || props.isCat === 'uni'
                                    ?   <input id={props.from === 'order' ? "add-input-name" : null} className={props.from !== 'order' ? 'add-input-name' : null} type="text" autoComplete="off" placeholder="Введите название"/>
                                    :   <select className="page-order-content-add-name" autoComplete="off" defaultValue="0" onChange={(e) => {
                                            props.setNom(e.target.value)
                                        }}>
                                            <option value="0" data-id="0" disabled="on">Выберите номенклатуру</option>
                                            {Object.keys(isSysDat.nomenclature).map((nomId, i) => {
                                                if(isSysDat.nomenclature[nomId].ca == props.isCat){
                                                    return (
                                                        <option value={isSysDat.nomenclature[nomId].id}
                                                                data-cat={isSysDat.nomenclature[nomId].ca
                                                                    ? isSysDat.nomenclature[nomId].ca
                                                                    : '0'
                                                                } key={i}>
                                                            {isSysDat.nomenclature[nomId].name}
                                                        </option>
                                                    )
                                                }else if(props.isCat == 0 && !isSysDat.nomenclature[nomId].ca){
                                                    return (
                                                        <option value={isSysDat.nomenclature[nomId].id} data-cat="0" key={i}>
                                                            {isSysDat.nomenclature[nomId].name}
                                                        </option>
                                                    )
                                                }
                                            })}
                                        </select>
                            }
                        </div>
                        <div>
                            { props.isCat !== '-'
                                ?  props.isCat === 'uni' || props.isCat === 'wrp' || props.isNom != 0
                                    ?   <div className={`page-order-content-add-btn ${!sumReady ? 'hideWhileCalc' : ''}`}
                                             onClick={(e) => {
                                                 let tmEl
                                                 if(props.from === 'order'){
                                                     tmEl = Q_('#add-input-name')
                                                     props.addElement(
                                                         props.isNom,
                                                         props.isCat,
                                                         tmEl ? tmEl.value : '',
                                                         '',
                                                         '',
                                                     )
                                                 }else{
                                                     tmEl = Q_('.nom[data-pos="'+props.paPos+'"] > .entrails > .add-wrp .add-input-name') 
                                                     props.addElement(
                                                         props.isNom,
                                                         props.isCat,
                                                         tmEl ? tmEl.value : '',
                                                         e.target,
                                                         props.paType,
                                                     )
                                                 }

                                                 setTimeout(function () {
                                                     props.setNom(0)
                                                     props.setCat('-')
                                                     props.setActive(false)
                                                 }, 100)
                                             }}
                                    >Добавить</div>
                                    :   ''
                                :   ''
                            }
                        </div>
                        <div className="page-order-content-add-close" onClick={() => {
                            props.setNom(0)
                            props.setCat('-')
                            props.setActive(false)
                        }}>×</div>
                    </div>
                :   <p className={`page-order-content-add-link ${props.from !== 'order' ? 'inlink' : ''}`} onClick={() => props.setActive(true)}><IcoPlus/> Добавить номенклатуру</p>
            }
        </div>
    )
};

export default BlockAddElement;